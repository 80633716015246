import React, { useState, useEffect, useRef }  from 'react';
import './App.css';
import { v4 as uuidv4 } from 'uuid';
import { db, firestore } from './admin';

const Completed = () => {
  return (
    <div className="center">
      <h1 className="colorWhite">Welcome in!</h1>
      <p>We will keep you informed once we are ready to launch, see ya soon!</p>
    </div>
  )
}
  
function App() {
  const firstRender = useRef(true)
  const [email, setEmail] = useState('');
  const [emailError, setEmailError] = useState(null);
  const [phone, setphone] = useState('');
  const [phoneError, setPhoneError] = useState(null);
  const [thankYou, setThankYou] = useState(null);
  const [disable, setDisabled] = useState(true)
  
const addSubscriber = e => {
  e.preventDefault();
  const userRef = db.collection('subscribers').doc(uuidv4()).set({
    phone,
    email,
    createdAt: firestore.FieldValue.serverTimestamp(),
  });  
  setThankYou(true);
};
  
  useEffect(() => {
    if (firstRender.current) {
      firstRender.current = false
      return
    }

    setDisabled(formValidation())
  }, [email, phone, thankYou]);
  
  const toValidate = (expression, value, errorFunction, errorMessage) => { 
    if (!value.match(expression)) {
      errorFunction(errorMessage)
      return true
    } else {
      errorFunction(null)
      return false
    }
  }  
  const formValidation = () => {
    toValidate(/\S+@\S+\.\S+/, email, setEmailError, 'Please make sure it is a valid Email');
    toValidate(/\d/g, phone, setPhoneError, 'Please make sure it is a valid Phone number');    
  }

  return (
    <div className="App">
      {!thankYou &&
        <form onSubmit={addSubscriber} noValidate>
          <div className="field">
            {/* <label className="label">Email Address</label> */}
            <div className="control">
              <input className="field-input" placeholder="Email" autoComplete="off" type="email" name="email" onChange={(e) => setEmail(e.target.value)} value={email || ''} required />
              {emailError && <small>{emailError}</small>}
            </div>
          </div>
          <div className="field">
            {/* <label className="label">Phone</label> */}
            <div className="control">
              <input className="field-input" placeholder="Phone" type="text" name="phone" pattern="[0-9]" onChange={(e) => setphone(e.target.value)} value={phone || ''} required />
              {phoneError && <small>{phoneError}</small>}
            </div>
          </div>
        
        
          <input className="submit-button" type="submit" disabled={disable} value="Subscribe" />
        </form>
      }
      { thankYou && <Completed /> }
    </div>
  );
}
export default App;
